<template>
  
  <HelloWorld msg="Welcome to Your Vue.js App"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style lang="scss">

html{
  min-height:100vh;
  max-width:800px;
  
  box-sizing:border-box;
  border-top:10px solid $important;
  border-bottom:10px solid $accent2;
  background:$bg;
  padding:0 0 0 0;
  margin:0 0 0 0;
   margin:0 auto 0 auto;
}
#app {
 
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
 // margin-top: 60px;
}
</style>
