<template>
  <div class="main-container">
    <div class="adnan-container">
      <div class="flex-item adnan-text">
    <p class="title name-title">السيرة الذاتي للمعلم</p>
    <p class="name">عدنان نوري الضاحي</p>
    </div>
    <img class="flex-item person" src="../assets/person.png"/>
    </div>
    <!-- <hr/> -->
    <div class="short-container">
      <p class="short"><span class="important">معلم</span> مادة دراسات عملية كهرباء، حاصل على <span class="important">شهادة البكالوريوس</span> مع مرتبة الشرف تخصص التربية من كلية التربية الأساسية، حاصل على <span class="important">شهادة الماجستير</span> في اللغة الإنجليزية تخصص ترجمة من جامعة الكويت</p>
    </div>
    <div class="card">
      <p class="workplace-title">
        مقر العمل السابق:
        <span class="workplace">أكاديمية الموهبة المشتركة</span>
      </p>
    </div>
    <div class="card">
      <p class="workplace-title">
        مقر العمل الحالي:
        <span class="workplace">مدرسة إياس بن معاذ، المتوسطة، بنين</span>
      </p>
    </div>
    <hr />
    <p @click="toggle(1)" class="title exp-title">الخبرات<ion-icon v-if="!expShown" class="arrow" name="caret-down-outline"></ion-icon><ion-icon v-if="expShown" class="arrow" name="caret-up-outline"></ion-icon></p>
    <hr class="underline" />
    <div v-if="expShown" class="card-container">
      <div class="card exp-card">
        <p class="exp">خبرة 11 سنة كمعلم في وزارة التربية.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">العمل في مجال تطوير المواقع والتطبيقات لمدة 16 سنة.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">العمل لمدة سنتين في أكاديمية الموهبة والابداع منذ تأسيسها مع الطلبة الموهوبين، وقمت بتصميم منهج خاص لطلبة الاكاديمية في تخصص الالكترونيات</p>
      </div>

      <div class="card exp-card">
        <p class="exp">العمل في التصميم ثلاثي الأبعاد لمدة 3 سنوات باستخدام 3dsmax. خبرة 16 سنة في التصميم ثنائي الأبعاد باستخدام Photoshop.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">خبرة في علم الـRobotics وتصميم عدد من الروبوتات الخاصة ، وتطوير نظام SmartHome للمنازل ببرمجة خاصة.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">خبرة في المتحكمات الدقيقة (MicroControllers) وخاصة الأردوينو والسنو بول و الرازبيري باي والـESP8266.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">العمل كمتطوع في مركز صباح الأحمد - فاب لاب – واكتساب خبرة في الـCNC و ال3d Printing و الـLaser Cutting.</p>
      </div>
    </div>

    <p @click="toggle(2)" class="title exp-title">مهارات الحاسب الآلي<ion-icon v-if="!pcShown" class="arrow" name="caret-down-outline"></ion-icon><ion-icon v-if="pcShown" class="arrow" name="caret-up-outline"></ion-icon></p>
    <hr class="underline" />
    <div v-if="pcShown" class="card-container">
      <div class="card exp-card">
        <p class="exp">معرفه تامة لجميع برامج Microsoft Office : Word, Excel, Powerpoint. الخ...</p>
      </div>
      <div class="card exp-card">
        <p class="exp">خبرة ممتازة في البرامج التالية : 3dsmax, Photoshop, Dreamweaver, Sony Vegas, Unity</p>
      </div>
      <div class="card exp-card">
        <p class="exp">خبرة ممتازة في لغات البرمجة التالية :, C C#, HTML, CSS, Javascript</p>
      </div>

      <div class="card exp-card">
        <p class="exp">خبرة ممتازة في الخوادم الإفتراضية الخاصة VPS</p>
      </div>
      <div class="card exp-card">
        <p class="exp">خبرة ممتازة في قواعد بيانات SQL</p>
      </div>
      <div class="card exp-card">
        <p class="exp">خبرة ممتازة في خدمة Firebase</p>
      </div>
      <div class="card exp-card">
        <p class="exp">خبرات متعددة في مختلف المجالات في الحاسب الآلي، والمتجددات التكنلوجية</p>
      </div>
    </div>

    <p @click="toggle(3)" class="title exp-title">اهم الإنجازات<ion-icon v-if="!achShown" class="arrow" name="caret-down-outline"></ion-icon><ion-icon v-if="achShown" class="arrow" name="caret-up-outline"></ion-icon></p>
    <hr class="underline" />
    <div v-if="achShown" class="card-container">
      <div class="card exp-card">
        <p class="exp">تدريب رؤساء أقسام الدراسات العملية في المتحكمات الدقيقة بدورة قامت في العام الدراسي 2015/2016</p>
      </div>

      <div class="card exp-card">
        <p class="exp">تدريب معلمين الدراسات في علم الروبوتات وبرمجة المتحكمات الدقيقة.</p>
      </div>

      <div class="card exp-card">
        <p class="exp">تقديم دورة عن الRobotics لفريق الروبوت في منظمة الملست - المكتب الإقليمي لقارة آسيا بدولة الكويت.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">الحصول على المركز الثاني في مسابقة التركيبات الصناعية للعام الدراسي 2014/2015.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">ترجمة شروحات الدوائر الإلكترونية المخصصة للصف السابع والصف التاسع في مادة الدراسات العملية / كهرباء.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">تصميم موقع e-physics.info ، وهو موقع تعليمي متكامل يختص بالتعليم عن بعد، وبتهيئة بيئة الفصل الدراسي في أي مكان، عن طريق الإنترنت.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">تصميم نظام SmartHome للتحكم بالمنازل عن طريق الهواتف الذكية باستخدام البلوتوث.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">تدريب طلبة جامعيين على المتحكمات الدقيقة (الأردوينو).</p>
      </div>
      <div class="card exp-card">
        <p class="exp">المشاركة في معرض MakerFaire لعام 2017 بمشروعين باستخدام الرازبيري باي والأردوينو.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">تصميم 4 روبوتات لمعرض "أركان" الخاص بتوجيه الدراسات العملية لمنطقة حولي التعليمية.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">العمل على تطوير نظام التحكم بالأردوينو والـE-Bot -السنوبول سابقا- عن طريق البلوتوث.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">برمجة نظام متكامل للتحكم بكهرباء المنزل او بالروبوتات عن طريق الإنترنت وذلك باستخدام الرازبيري باي والأردوينو، لإتمام المشروع قمت باستخدام HTML, CSS, Javascript (Jquery), C#, C.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">تصميم وبرمجة بادج -Badge- الكتروني بشاشة لمس ممكن عرض فيديو او صور متغيرة عليها.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">برمجة سيرفر مع موقع انترنت على قطعة الرازبيري باي يقوم بعرض بث مباشر لكاميرا مراقبة للمنزل مع إمكانية التحكم بإضاءة الغرف.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">تصميم مواقع الكترونيىة متكاملة لمدرسة اكاديمية الموهبة ومدرسة اياس بن معاذ.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">التدريس في كلية التربية الأساسية</p>
      </div>
      <div class="card exp-card">
        <p class="exp">تصميم ونشر برنامج Reverse Zero مع شركة Apple، وذلك عن طريق الAppstore.</p>
      </div>
      <div class="card exp-card">
        <p class="exp">تصميم ونشر برنامج عداد التسبيح مع شركة Google، وذلك عن طريق الPlay Store</p>
      </div>
    </div>
    
  </div>
  <div class="footer">
     <img class="logo" src="../assets/logo.png"/>
    <p class="disclaimer">قمت بتصميم هذا الموقع باستخدام لغة Javascript، ولغة التنصيص HTMLوCSS، وقمت أيضا باستخدام VUE Framework</p>
    </div>
</template>

<script>
export default {
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      expShown: true,
      pcShown: true,
      achShown: true,
    }
  },
  methods: {
    toggle(id) {
      if (id == 1) {
        this.expShown = !this.expShown
        //this.pcShown = false;
        //this.achShown = false;
      }
      if (id == 2) {
        //this.expShown = false;
        this.pcShown = !this.pcShown
        //this.achShown = false;
      }
      if (id == 3) {
        // this.expShown = false;
        //this.pcShown = false;
        this.achShown = !this.achShown
      }
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.name-title {
  color: $text;
}
.main-container {
  //min-height: 80vh;
  
  .short-container {
    //background:$darker-black;
    //border:1px solid $purple;
    border-top: 1px solid $accent2;
    border-bottom: 1px solid $accent2;
    //@include border-radius(5px);
    margin-top: 20px;
    padding: 10px;
  }
  .title {
    font-family: $cairo;
    font-size: 18px;
    margin: 0 0 0 0;
  }
  .name {
    font-size: 30px;
    color: $important;
    font-family: $amiri;
    margin: 0 0 0 0;
  }
  .short {
    color: $text;
    font-family: $tajwal;
    font-size: 16px;
    text-align: justify;
    margin: 0 0 0 0;
  }
  .important {
    color: $important;
  }
}
hr {
  border: none;
  margin-top: 20px;
  border-top: 1px solid $accent;
  width: 100%;
}
.card {
  background: #181c22;
  border: 1px solid $accent;
  @include border-radius(5px);
  padding: 10px;
  margin-top: 10px;
  p {
    margin: 0 0 0 0;
  }
  .workplace-title {
    color: $text;
    font-size: 16px;
    font-family: $tajwal;
    text-align: right;
  }
  .workplace {
    color: $important;
    font-size: 18px;
    font-family: $amiri;
    text-align: center;
  }
}
.exp-title {
  text-align: right;
  color: $accent2;
  display: flex;
  align-items: center;
}
.exp {
  color: $text;
  font-family: $tajwal;
  font-size: 14px;
  text-align: right;
  justify-content: right;
  margin: 0 0 0 0;
}
.exp-card {
  border: none;
  //border:1px solid $accent;
}
#uparrow:before {
  content: "\276F";
}

.underline {
  border: none;
  margin-top: 5px;
  border-top: 1px solid $accent2;
}
.arrow {
  margin-right: 5px;
}
.card-container {
  margin-bottom: 30px;
}
.disclaimer{
  font-family: $tajwal;
  color:#5a6069;
}
.logo{
  width:50px;
}

.footer{

    margin-top: 50px;
}
.adnan-container{
  display: flex;
align-items: center;
}
.flex-item{
  flex:1;
}
.person{
  max-width:150px;
  @include border-radius(5px);
}
</style>
